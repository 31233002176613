import React, { useRef, useState } from 'react';

import Container from "@material-ui/core/Container";
import ReactPlayer from "react-player";
import { makeStyles } from "@material-ui/core/styles";

import screenful from "screenfull";
import KeyboardEventHandler from "react-keyboard-event-handler";

import VideoPlayerControls from './VideoPlayerControls';
import { Button } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { useEffect } from 'react';

import api from "../../../Environment";

const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        width: "100%",
        position: "relative",
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'maroon',
        color: 'white',
        "&:hover": {
            color: '#333'
        }
    },
    controlIcons: {
        color: "#777",

        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },

    volumeSlider: {
        width: 100,
    },
}));

const format = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};

let count = 0;
let ppvUpdated = false;
let videoPlayedSeconds = 0;
let initialSeekDone = false;

const VideoPlayer = ({ videoURL, skipIntroStart, skipIntroEnd, skipCreditsStart, skipCreditsEnd, initialSeekToSeconds, ppvValue, updatePPV, updateWatchedDuration, playAnotherVideo, videoDetails }) => {
    const classes = useStyles();
    const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
    const [videoResolutions, setVideoResolutions] = useState([]);
    const [playbackResolution, setPlaybackResolution] = useState(-1);
    const [availableSubtitles, setAvailableSubtitles] = useState([]);
    const [displaySubtitle, setDisplaySubtitle] = useState(false);
    const [selectedSubtitle, setSelectedSubtitle] = useState(0);
    const [episodes, setEpisodes] = useState();
    const [prevVideoID, setPrevVideoID] = useState();
    const [nextVideoID, setNextVideoID] = useState();

    const [state, setState] = useState({
        pip: false,
        playing: true,
        light: false,
        muted: false,
        played: 0,
        duration: 0,
        playbackRate: 1.0,
        volume: 1,
        loop: false,
        displaySkip: false,
        skipType: 0
    });

    useEffect(() => {
        initialSeekDone = false;
        if (videoDetails.is_series) {
            api.postMethod("genre_videos", { page_type: "SERIES", category_id: videoDetails.category_id, sub_category_id: videoDetails.sub_category_id, genre_id: videoDetails.genre_id, skip: 0 }).then((response) => {
                if (response.data.success === true) {
                    const episodesList = response.data.data;
                    setEpisodes(episodesList);
                    if (episodesList) {
                        for (let idx = 0; idx < episodesList.length; idx++) {
                            if (episodesList[idx].admin_video_id === videoDetails.admin_video_id) {
                                if (idx > 0) {
                                    setPrevVideoID(episodesList[idx - 1].admin_video_id);
                                    console.log('Previous Video ID:', episodesList[idx - 1].admin_video_id);
                                }
                                if (idx < episodesList.length - 1) {
                                    setNextVideoID(episodesList[idx + 1].admin_video_id);
                                    console.log('Next Video ID:', episodesList[idx + 1].admin_video_id);
                                }
                            }
                        }
                    }
                    console.log(response.data);
                }
            }).catch(function (error) { });
        }
    }, []);

    const playerRef = useRef(null);
    const playerContainerRef = useRef(null);
    const controlsRef = useRef(null);
    const {
        playing,
        light,
        muted,
        loop,
        playbackRate,
        pip,
        played,
        volume,
        displaySkip,
        skipType
    } = state;

    const handleReady = () => {
        let hlsPlayer = playerRef.current.getInternalPlayer('hls');
        if (hlsPlayer) {
            setVideoResolutions(hlsPlayer.levels);
            setPlaybackResolution(hlsPlayer.currentLevel);
            setAvailableSubtitles(hlsPlayer.subtitleTracks);
            setSelectedSubtitle(hlsPlayer.subtitleTrack);
            setDisplaySubtitle(hlsPlayer.subtitleDisplay)
        } else {
            console.log('Non HLS.');
        }
        const totalDurationSeconds = playerRef && playerRef.current ? playerRef.current.getDuration() : 0;
        if (initialSeekToSeconds && totalDurationSeconds && !initialSeekDone) {
            setState({ ...state, played: parseFloat(initialSeekToSeconds / totalDurationSeconds) });
            playerRef.current.seekTo(parseFloat(initialSeekToSeconds / totalDurationSeconds), "fraction");
            initialSeekDone = true;
        }

    }

    const handlePlayPause = () => {
        setState({ ...state, playing: !state.playing });
    }

    const handleRestart = () => {
        playerRef.current.seekTo(0);
    }

    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    }

    const handleFastForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }

    const handleProgress = (changeState) => {
        if (count > 3) {
            controlsRef.current.style.visibility = "hidden";
            count = 0;
        }
        if (controlsRef.current.style.visibility === "visible") {
            count += 1;
        }
        let displaySkipButton = false;
        let displaySkipType = 0;
        if (skipIntroStart != skipIntroEnd && changeState.playedSeconds >= skipIntroStart && changeState.playedSeconds <= skipIntroEnd) {
            displaySkipButton = true;
            displaySkipType = 1;
        } else if (skipCreditsStart != skipCreditsEnd && changeState.playedSeconds >= skipCreditsStart && changeState.playedSeconds <= skipCreditsEnd) {
            displaySkipButton = true;
            displaySkipType = 2;
        }

        if (!ppvUpdated && ppvValue > 0 && changeState.playedSeconds >= ppvValue) {
            updatePPV();
            ppvUpdated = true;
        }

        if (Math.abs(changeState.playedSeconds - videoPlayedSeconds) >= 5) {
            videoPlayedSeconds = changeState.playedSeconds;
            updateWatchedDuration(videoPlayedSeconds);
        }
        if (!state.seeking) {
            setState({ ...state, played: changeState.played, displaySkip: displaySkipButton, skipType: displaySkipType });
        }
        let hlsPlayer = playerRef.current.getInternalPlayer('hls');
        setPlaybackResolution(hlsPlayer.currentLevel);
    };

    const handleSeekChange = (e, newValue) => {
        setState({ ...state, played: parseFloat(newValue / 100) });
    };

    const handleSeekMouseDown = (e) => {
        setState({ ...state, seeking: true });
    };

    const handleSeekMouseUp = (e, newValue) => {
        setState({ ...state, seeking: false });
        playerRef.current.seekTo(newValue / 100, "fraction");
    };

    const handleDuration = (duration) => {
        setState({ ...state, duration });
    };

    const handleVolumeSeekDown = (e, newValue) => {
        setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
    };
    const handleVolumeChange = (e, newValue) => {
        setState({
            ...state,
            volume: parseFloat(newValue / 100),
            muted: newValue === 0 ? true : false,
        });
    };

    const handleResolutionChange = (newValue) => {
        let hlsPlayer = playerRef.current.getInternalPlayer('hls');
        hlsPlayer.currentLevel = newValue;
        setPlaybackResolution(newValue);
    }

    const handleSubtitleHide = () => {
        let hlsPlayer = playerRef.current.getInternalPlayer('hls');
        hlsPlayer.subtitleDisplay = false;
        hlsPlayer.subtitleTrack = -1;
        setDisplaySubtitle(hlsPlayer.subtitleDisplay);
    }

    const handleSubtitleLangChange = (newValue) => {
        let hlsPlayer = playerRef.current.getInternalPlayer('hls');
        hlsPlayer.subtitleDisplay = true;
        hlsPlayer.subtitleTrack = newValue;
        setDisplaySubtitle(true);
        setSelectedSubtitle(newValue);
    }

    const toggleFullScreen = () => {
        screenful.toggle(playerContainerRef.current);
    };

    const handleMouseMove = () => {
        controlsRef.current.style.visibility = "visible";
        count = 0;
    };

    const hanldeMouseLeave = () => {
        controlsRef.current.style.visibility = "hidden";
        count = 0;
    };

    const hanldeMouseClick = () => {
        controlsRef.current.style.visibility = "visible";
        count = 0;
        //handlePlayPause();
    };

    const handleDisplayFormat = () => {
        setTimeDisplayFormat(
            timeDisplayFormat === "normal" ? "remaining" : "normal"
        );
    };

    const handlePlaybackRate = (rate) => {
        setState({ ...state, playbackRate: rate });
    };

    const hanldeMute = () => {
        setState({ ...state, muted: !state.muted });
    };

    const handleSkipIntro = () => {
        setState({ ...state, displaySkip: false });
        playerRef.current.seekTo(skipIntroEnd);
    }

    const handleSkipCredit = () => {
        setState({ ...state, displaySkip: false });
        playerRef.current.seekTo(skipCreditsEnd);
    }

    const currentTime =
        playerRef && playerRef.current
            ? playerRef.current.getCurrentTime()
            : "00:00";

    const duration =
        playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
    const elapsedTime =
        timeDisplayFormat === "normal"
            ? format(currentTime)
            : `-${format(duration - currentTime)}`;

    const totalDuration = format(duration);

    return (
        <div style={{ height: '70vh', margin: 0, padding: 0 }}>
            <KeyboardEventHandler
                handleKeys={['space', 'left', 'right', 'up', 'down']}
                onKeyEvent={(key, e) => {
                    if (key == 'space') {
                        handlePlayPause();
                    } else if (key == 'left') {
                        handleRewind();
                    } else if (key == 'right') {
                        handleFastForward();
                    }
                }} />
            <Container maxWidth="xl">
                <div style={{ height: '99vh' }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={hanldeMouseLeave}
                    onClick={hanldeMouseClick}
                    ref={playerContainerRef}
                    className={classes.playerWrapper}
                >
                    <ReactPlayer
                        ref={playerRef}
                        width="100%"
                        height="100%"
                        url={videoURL}
                        //url="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
                        pip={pip}
                        playing={playing}
                        controls={false}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onReady={handleReady}
                        onProgress={handleProgress}
                        config={{
                            file: {
                                attributes: {
                                    crossOrigin: "anonymous",
                                    controlsList: "nodownload"
                                },
                            },
                        }}
                    />

                    <VideoPlayerControls
                        ref={controlsRef}
                        onSeek={handleSeekChange}
                        onSeekMouseDown={handleSeekMouseDown}
                        onSeekMouseUp={handleSeekMouseUp}
                        onDuration={handleDuration}
                        onRestart={handleRestart}
                        onPrevious={() => { playAnotherVideo(prevVideoID) }}
                        onRewind={handleRewind}
                        onPlayPause={handlePlayPause}
                        onFastForward={handleFastForward}
                        onNext={() => { playAnotherVideo(nextVideoID) }}
                        playing={playing}
                        played={played}
                        elapsedTime={elapsedTime}
                        totalDuration={totalDuration}
                        onMute={hanldeMute}
                        muted={muted}
                        onVolumeChange={handleVolumeChange}
                        onVolumeSeekDown={handleVolumeSeekDown}
                        onChangeDispayFormat={handleDisplayFormat}
                        playbackRate={playbackRate}
                        onPlaybackRateChange={handlePlaybackRate}
                        playbackResolution={playbackResolution}
                        onResolutionChange={handleResolutionChange}
                        videoResolutions={videoResolutions}
                        availableSubtitles={availableSubtitles}
                        selectedSubtitle={selectedSubtitle}
                        displaySubtitle={displaySubtitle}
                        onChangeSubtitleLang={handleSubtitleLangChange}
                        onSubtitleHide={handleSubtitleHide}
                        onToggleFullScreen={toggleFullScreen}
                        volume={volume}
                        prevVideoID={prevVideoID}
                        nextVideoID={nextVideoID}
                        episodes={episodes}
                        playAnotherVideo={playAnotherVideo}
                    />
                    {displaySkip == true && skipType == 1 && (<Button onClick={handleSkipIntro} className={classes.button} variant="contained" startIcon={<SkipNextIcon />} style={{ position: "absolute", bottom: 150, right: 0 }}>Skip Intro</Button>)}
                    {displaySkip == true && skipType == 2 && (<Button onClick={handleSkipCredit} className={classes.button} variant="contained" startIcon={<SkipNextIcon />} style={{ position: "absolute", bottom: 150, right: 0 }}>Skip Credits</Button>)}
                </div>
            </Container>
        </div>
    );
}

export default VideoPlayer;