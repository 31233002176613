import React from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import configuration from "react-global-configuration";
import { translate } from "react-multi-lang";
import countryCodes from './CountryCodes.json';
import publicIp from "public-ip";

var ip_address = '127.0.0.1';
var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class LoginOTP extends Helper {
    state = {
        data: {
            country_code: "+91",
            mobile: "",
            otp: ""
        },
        buttonLabel: this.props.t("generate_otp"),
        buttonDisable: false,
        loginStatus: 0 //0-Pending, 1-OTP Request Sent, 2-OTP Verified
    };

    getClientIp = async () => await publicIp.v4({
        fallbackUrls: ["https://ifconfig.co/ip"]
    });

    componentDidMount() {
        this.getClientIp().then(ipaddress => {
            ip_address = ipaddress;
        }).catch(err => console.log('Get IP Address Failed : ', err));
    }

    sendOTP = () => {
        if (this.state.data.country_code && this.state.data.mobile) {
            this.setState({ buttonDisable: true });
            let inputData = {
                mobile_no: this.state.data.country_code + this.state.data.mobile,
                ip_address: ip_address
            };
            api.postMethod("send_otp", inputData).then((response) => {
                if (response.data.success === true) {
                    this.setState({
                        loginStatus: 1,
                        buttonDisable: false,
                        buttonLabel: this.props.t("verify_otp")
                    });
                    ToastDemo(this.props.toastManager, response.data.message, "success");
                } else {
                    ToastDemo(
                        this.props.toastManager,
                        response.data.message || response.data.error_messages,
                        "error"
                    );
                    this.setState({
                        requesting_otp: false
                    });
                }
            }).catch((error) => {
                ToastDemo(this.props.toastManager, error, "error");
            }).finally(() => {
                this.setState({ buttonDisable: false });
            });
        } else {
            ToastDemo(this.props.toastManager, "Please fill in your country code and mobile number", "error");
        }
    }

    verifyOTP = () => {
        if (this.state.data.country_code && this.state.data.mobile && this.state.data.otp) {
            this.setState({ buttonDisable: true });
            let inputData = {
                mobile_no: this.state.data.country_code + this.state.data.mobile,
                timezone: const_time_zone,
                ip_address: ip_address,
                otp: this.state.data.otp
            };
            api.postMethod("verify_otp", inputData).then((response) => {
                if (response.data.success === true) {
                    this.setState({
                        loginStatus: 1,
                        buttonDisable: false,
                        buttonLabel: this.props.t("verify_otp")
                    });
                    localStorage.setItem("userId", response.data.data.user_id);
                    localStorage.setItem("accessToken", response.data.data.token);
                    localStorage.setItem("userType", response.data.data.user_type);
                    localStorage.setItem("push_status", response.data.data.push_status);
                    localStorage.setItem("username", response.data.data.name);
                    localStorage.setItem(
                        "active_profile_id",
                        response.data.data.sub_profile_id
                    );
                    this.props.history.push("/view-profiles");
                    ToastDemo(this.props.toastManager, response.data.message, "success");
                } else {
                    ToastDemo(
                        this.props.toastManager,
                        response.data.message,
                        "error"
                    );
                }
            }).catch((error) => {
                ToastDemo(this.props.toastManager, error, "error");
            }).finally(() => {
                this.setState({ buttonDisable: false });
            });;
        } else {
            ToastDemo(this.props.toastManager, "Please fill in your country code, mobile number and OTP", "error");
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.loginStatus === 0) {
            this.sendOTP();
        } else if (this.state.loginStatus === 1) {
            this.verifyOTP();
        }
    }

    editMobile = () => {
        this.setState({
            loginStatus: 0,
            buttonLabel: this.props.t("generate_otp")
        });
    }

    render() {
        const { t } = this.props;
        var bgImg = {
            backgroundImage: `url(${configuration.get(
                "configData.common_bg_image"
            )})`,
        };
        const { data } = this.state;
        return (
            <div>
                <div className="common-bg-img" style={bgImg}>
                    <div className="auth-page-header">
                        <Link to={"/"}>
                            <img
                                src={configuration.get("configData.site_logo")}
                                className="site-logo"
                                alt="logo_img"
                            />
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
                            <div className="register-box">
                                <h3 className="register-box-head">{t("signin")}</h3>
                                <form onSubmit={this.handleSubmit} className="auth-form login-new-form">
                                    {(this.state.loginStatus === 0) && (
                                        <div className="form-group">
                                            <select className="form-control col-md-3 float-left" id="country_code" name="country_code" style={{ height: "60px", fontSize: "18px" }} onChange={this.handleChange} value={data.country_code}>
                                                {countryCodes.map((country, index) => {
                                                    return <option key={index} value={country.dial_code}>{country.dial_code}</option>
                                                })}
                                            </select>
                                            <input
                                                type="text"
                                                onChange={this.handleChange}
                                                className="form-control col-md-8 float-left ml-1"
                                                id="mobile"
                                                name="mobile"
                                                placeholder="Phone Number"
                                                value={data.mobile}
                                            />
                                        </div>
                                    )}
                                    {(this.state.loginStatus > 0) && (
                                        <>
                                            <p> Your verification code has been sent to<br /> <strong>{data.mobile} <a href="#" onClick={this.editMobile}><i className="far fa-edit" /></a></strong></p>

                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    className="form-control col-md-12 float-left ml-1"
                                                    id="otp"
                                                    name="otp"
                                                    placeholder="Enter OTP"
                                                    disabled={this.state.loginStatus !== 1}
                                                    value={data.otp}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <a className="btn-link float-right" href="#" onClick={this.sendOTP}>Resend OTP</a>
                                            </div>
                                        </>
                                    )}
                                    <br /><br /><br />
                                    <div className="form-group">
                                    <span style={{fontSize:"16px",color:"#999"}}>By signing in, you agree to the Talkiez Video Terms of Use and license agreements as per the website.</span>
                                    </div>
                                    <div className="form-group">
                                        <button
                                            className="btn btn-danger auth-btn"
                                            disabled={this.state.buttonDisable}
                                        >
                                            {this.state.buttonLabel}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withToastManager(translate(LoginOTP));