import { Component } from "react";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import api from "../../Environment";

import { translate } from "react-multi-lang";

class Logout extends Component {

    logoutApiCall = () => {
        let userId =
            localStorage.getItem("userId") !== "" &&
                localStorage.getItem("userId") !== null &&
                localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";
        let accessToken =
            localStorage.getItem("accessToken") !== "" &&
                localStorage.getItem("accessToken") !== null &&
                localStorage.getItem("accessToken") !== undefined
                ? localStorage.getItem("accessToken")
                : "";

        let logoutData = { id: userId, token: accessToken, signout_from_all_device: 0 }
        api.postMethod("logout", logoutData).then(response => {
            if (response.data.success) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                localStorage.removeItem("userLoginStatus");
                localStorage.removeItem("push_status");
                localStorage.removeItem("active_profile_id");
                localStorage.removeItem("userType");

                this.props.history.push("/");
                ToastDemo(this.props.toastManager, this.props.t("logout_success"), "success");
            } else {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                localStorage.removeItem("userLoginStatus");
                localStorage.removeItem("push_status");
                localStorage.removeItem("active_profile_id");
                localStorage.removeItem("userType");

                this.props.history.push("/");
                ToastDemo(
                    this.props.toastManager,
                    response.data.error_messages,
                    "error"
                );
            }
        }).catch(error => {
            ToastDemo(this.props.toastManager, error, "error");
        });
    };

    componentDidMount() {
        this.logoutApiCall();
    }
    render() {
        return null;
    }
}

export default withToastManager(translate(Logout));
