import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .65)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .8)',
    color: '#CCC',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .8)',
    color: '#CCC',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const VideoPlayerEpisodesList = ({ episodes, playAnotherVideo }) => {
  const [expanded, setExpanded] = React.useState();

  const expandEpisode = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ maxWidth: 900 }}>
      {episodes && episodes.map((episode, index) => {
        return (
          <Accordion key={'acc-' + index} square expanded={expanded === 'panel' + index} onChange={expandEpisode('panel' + index)}>
            <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
              <Typography>{episode.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <a href="#" onClick={(e) => { e.preventDefault(); playAnotherVideo(episode.admin_video_id) }}><img src={episode.default_image} style={{ width: '100px' }} alt={'moviesnap'} /></a>
              </Typography>&nbsp;&nbsp;&nbsp;
              <Typography>
                <a href="#" onClick={(e) => { e.preventDefault(); playAnotherVideo(episode.admin_video_id) }}>{episode.description}</a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  );
}

export default VideoPlayerEpisodesList;