import React, { Component } from "react";

import UserHeader from "./SubLayout/UserHeader";

import Footer from "../Layouts/SubLayout/Footer";

class AuthLayout extends Component {
  constructor(props) {
    super(props);

    this.eventEmitter = this.props.screenProps;

    let userId =
      localStorage.getItem("userId") !== "" &&
        localStorage.getItem("userId") !== null &&
        localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";

    let accessToken =
      localStorage.getItem("accessToken") !== "" &&
        localStorage.getItem("accessToken") !== null &&
        localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

    this.state = {
      isAuthenticated: userId && accessToken ? true : false,
      searchInput: '',
      searchList: []
    };
  }

  render() {
    var { isAuthenticated, searchInput, searchList } = this.state;
    //console.log('searchInput', searchInput)
    //console.log('searchInput', searchList)
    var searchView =
      // searchInput && searchInput.length > 0 ? <div>{searchList.map((e) => {
      //   return <h2>data</h2>
      // })}</div> :
      this.props.children;

    return (
      <div className="wrapper">
        <div className="main-sec-content">
          <UserHeader onCallBack={(input, data) => {
            this.setState({
              searchInput: input,
              searchList: data,
            })
          }} />
          {React.cloneElement(searchView, {
            eventEmitter: this.eventEmitter,
            data: isAuthenticated
          })}
        </div>
        <Footer />
      </div>
    );
  }
}
export default AuthLayout;
