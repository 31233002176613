import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import VideocamIcon from '@material-ui/icons/Videocam';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import Popover from "@material-ui/core/Popover";
import VideoPlayerEpisodesList from "./VideoPlayerEpisodesList";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
    "&:focus": {
      outline: "none"
    }
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
    "&:focus": {
      outline: "none"
    }
  },

  volumeSlider: {
    width: 100,
    color: 'maroon'
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    color: 'red'
  },
  rail: {
    height: 8,
    borderRadius: 4,
    color: '#666'
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const VideoPlayerControls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRestart,
      onPrevious,
      onRewind,
      onPlayPause,
      onFastForward,
      onNext,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      videoResolutions,
      playbackResolution,
      onResolutionChange,
      availableSubtitles,
      displaySubtitle,
      selectedSubtitle,
      onChangeSubtitleLang,
      onSubtitleHide,
      onToggleFullScreen,
      volume,
      onVolumeChange,
      prevVideoID,
      nextVideoID,
      episodes,
      playAnotherVideo
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [episodesAnchorEl, setEpisodesAnchorEl] = React.useState(null);
    const [playbackAnchorEl, setPlaybackAnchorEl] = React.useState(null);
    const [resolutionAnchorEl, setResolutionAnchorEl] = React.useState(null);
    const [subtitleAnchorEl, setSubtitleAnchorEl] = React.useState(null);

    const handleEpisodesPopoverClick = (event) => {
      setEpisodesAnchorEl(event.currentTarget);
    };

    const handleEpisodesPopoverClose = () => {
      setEpisodesAnchorEl(null);
    };

    const handlePlaybackPopoverClick = (event) => {
      setPlaybackAnchorEl(event.currentTarget);
    };

    const handlePlaybackPopoverClose = () => {
      setPlaybackAnchorEl(null);
    };

    const handleResolutionPopoverClick = (event) => {
      setResolutionAnchorEl(event.currentTarget);
    };

    const handleResolutionPopoverClose = () => {
      setResolutionAnchorEl(null);
    };

    const handleSubtitlePopoverClick = (event) => {
      setSubtitleAnchorEl(event.currentTarget);
    };

    const handleSubtitlePopoverClose = () => {
      setSubtitleAnchorEl(null);
    };

    const episodesPopoverOpen = Boolean(episodesAnchorEl);
    const episodesPopoverId = episodesPopoverOpen ? "episodes-popover" : undefined;

    const playbackPopoverOpen = Boolean(playbackAnchorEl);
    const playbackPopoverId = playbackPopoverOpen ? "playback-popover" : undefined;

    const resolutionPopoverOpen = Boolean(resolutionAnchorEl);
    const resolutionPopoverId = resolutionPopoverOpen ? "resolution-popover" : undefined;

    const subtitlePopoverOpen = Boolean(subtitleAnchorEl);
    const subtitlePopoverId = subtitlePopoverOpen ? "subtitle-popover" : undefined;

    return (
      <div ref={ref} className={classes.controlsWrapper}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ flexGrow: 1 }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{ padding: 16 }}
          >

          </Grid>
          <Grid container direction="row" alignItems="center" justify="center">
            <IconButton
              onClick={onPlayPause}
              className={classes.controlIcons}
              aria-label="play"
            >
              {playing ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>
          </Grid>
          {/* bottom controls */}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Grid item xs={12}>
              <PrettoSlider
                min={0}
                max={100}
                ValueLabelComponent={(props) => (
                  <ValueLabelComponent {...props} value={elapsedTime} />
                )}
                aria-label="custom thumb label"
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
              //onDuration={onDuration}
              />
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <IconButton
                  onClick={onRestart}
                  className={classes.controlIcons}
                  aria-label="restart">
                  <RotateLeftIcon
                    className={classes.controlIcons}
                    fontSize="inherit"
                  />
                </IconButton>
                {prevVideoID && (<IconButton
                  className={classes.controlIcons}
                  onClick={onPrevious}
                  aria-label="skip-previous">
                  <SkipPreviousIcon
                    className={classes.controlIcons}
                    fontSize="inherit"
                  />
                </IconButton>)}
                <IconButton
                  onClick={onRewind}
                  className={classes.controlIcons}
                  aria-label="rewind"
                >
                  <FastRewindIcon
                    className={classes.controlIcons}
                    fontSize="inherit"
                  />
                </IconButton>
                <IconButton
                  onClick={onPlayPause}
                  className={classes.bottomIcons}
                  fontSize="inherit"
                >
                  {playing ? (
                    <PauseIcon className={classes.controlIcons} fontSize="inherit" />
                  ) : (
                    <PlayArrowIcon className={classes.controlIcons} fontSize="inherit" />
                  )}
                </IconButton>
                <IconButton
                  onClick={onFastForward}
                  className={classes.controlIcons}
                  aria-label="forward"
                >
                  <FastForwardIcon fontSize="inherit" />
                </IconButton>
                {nextVideoID && (<IconButton
                  onClick={onNext}
                  className={classes.controlIcons}
                  aria-label="skip-next">
                  <SkipNextIcon
                    className={classes.controlIcons}
                    fontSize="inherit"
                  />
                </IconButton>)}
                <Button
                  variant="text"
                  onClick={onChangeDispayFormat}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#fff", marginLeft: 16 }}
                  >
                    {elapsedTime}/{totalDuration}
                  </Typography>
                </Button>
                {episodes !== undefined && episodes.length > 0 && (
                  <Button
                    onClick={handleEpisodesPopoverClick}
                    aria-describedby={episodesPopoverId}
                    className={classes.bottomIcons}
                    variant="text"
                    startIcon={<VideoLibraryIcon />}
                  >
                    <Typography>Episodes</Typography>
                  </Button>
                )}
                <Popover
                  container={ref.current}
                  open={episodesPopoverOpen}
                  id={episodesPopoverId}
                  onClose={handleEpisodesPopoverClose}
                  anchorEl={episodesAnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <VideoPlayerEpisodesList episodes={episodes} playAnotherVideo={playAnotherVideo} />
                </Popover>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Slider
                  min={0}
                  max={100}
                  value={muted ? 0 : volume * 100}
                  onChange={onVolumeChange}
                  aria-labelledby="input-slider"
                  className={classes.volumeSlider}
                  onMouseDown={onSeekMouseDown}
                  onChangeCommitted={onVolumeSeekDown}
                />
                <IconButton
                  // onClick={() => setState({ ...state, muted: !state.muted })}
                  onClick={onMute}
                  className={`${classes.bottomIcons} ${classes.volumeButton}`}
                >
                  {muted ? (
                    <VolumeMute fontSize="large" />
                  ) : volume > 0.5 ? (
                    <VolumeUp fontSize="large" />
                  ) : (
                    <VolumeDown fontSize="large" />
                  )}
                </IconButton>

                {availableSubtitles && availableSubtitles.length > 0 && (
                  <>
                    <IconButton
                      onClick={handleSubtitlePopoverClick}
                      aria-describedby={subtitlePopoverId}
                      className={classes.bottomIcons}
                      variant="text"
                    >
                      <ClosedCaptionIcon fontSize="large" style={{ color: (displaySubtitle) ? 'maroon' : 'inherit' }} />
                    </IconButton>

                    <Popover
                      container={ref.current}
                      open={subtitlePopoverOpen}
                      id={subtitlePopoverId}
                      onClose={handleSubtitlePopoverClose}
                      anchorEl={subtitleAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Grid container direction="column-reverse">
                        <Button onClick={() => onSubtitleHide()} variant="text">
                          <Typography color={!displaySubtitle ? "secondary" : "inherit"} style={{ fontSize: '16px' }} >None</Typography>
                        </Button>
                        {availableSubtitles.map((subtitleLang, index) => (
                          <Button key={index} onClick={() => onChangeSubtitleLang(index)} variant="text">
                            <Typography color={(displaySubtitle && index === selectedSubtitle) ? "secondary" : "inherit"} style={{ fontSize: '16px' }} >{subtitleLang.name}</Typography>
                          </Button>
                        ))}
                      </Grid>
                    </Popover>
                  </>
                )}

                {videoResolutions && videoResolutions.length > 1 && (
                  <>
                    <IconButton
                      onClick={handleResolutionPopoverClick}
                      aria-describedby={resolutionPopoverId}
                      className={classes.bottomIcons}
                      variant="text"
                    >
                      <VideocamIcon fontSize="large" />
                    </IconButton>

                    <Popover
                      container={ref.current}
                      open={resolutionPopoverOpen}
                      id={resolutionPopoverId}
                      onClose={handleResolutionPopoverClose}
                      anchorEl={resolutionAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Grid container direction="column-reverse">
                        {videoResolutions.map((level, index) => (
                          <Button key={index} onClick={() => onResolutionChange(index)} variant="text">
                            <Typography color={index === playbackResolution ? "secondary" : "inherit"} style={{ textTransform: "lowercase", fontSize: '16px' }} >{level.height}p</Typography>
                          </Button>
                        ))}
                      </Grid>
                    </Popover>
                  </>
                )}

                <Button
                  onClick={handlePlaybackPopoverClick}
                  aria-describedby={playbackPopoverId}
                  className={classes.bottomIcons}
                  variant="text"
                >
                  <Typography>{playbackRate}X</Typography>
                </Button>

                <Popover
                  container={ref.current}
                  open={playbackPopoverOpen}
                  id={playbackPopoverId}
                  onClose={handlePlaybackPopoverClose}
                  anchorEl={playbackAnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Grid container direction="column-reverse">
                    {[0.5, 0.75, 1, 1.25, 1.5].map((rate) => (
                      <Button
                        key={rate}
                        //   onClick={() => setState({ ...state, playbackRate: rate })}
                        onClick={() => onPlaybackRateChange(rate)}
                        variant="text"
                      >
                        <Typography
                          color={rate === playbackRate ? "secondary" : "inherit"}
                        >
                          {rate}X
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Popover>
                <IconButton
                  onClick={onToggleFullScreen}
                  className={classes.bottomIcons}
                >
                  <FullScreen fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);

VideoPlayerControls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
};
export default VideoPlayerControls;
