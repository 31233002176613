import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import api from "../../Environment";

import Slider from "../SliderView/MainSlider";
import HomePageBanner from "./homePageBanner";
// import ContentLoader from "../Static/contentLoader";
import HomeLoader from "../Loader/HomeLoader";

import {
  // setTranslations,
  // setDefaultLanguage,
  translate,
} from "react-multi-lang";
// import en from "../translation/en.json";
// import pt from "../translation/pt.json";

class Home extends Component {
  state = {
    maindata: null,
    maindata2: null,
    errorHandle: 0,
    loading: true,
    loading2: true,
    banner: null,
    tokenInvalid: false
  };

  componentDidMount() {
    const inputData = {
      page_type: "HOME",
    };
    api
      .postMethod("home_first_section", inputData)
      .then((response) => {
        //console.log(response);
        if (response.data.success === true) {
          let maindata = response.data.data;
          let banner = response.data.banner;

          this.setState({
            loading: false,
            maindata: maindata,
            banner: banner,
          });
        } else if (response.data.invalid_token === true) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userId");
          localStorage.removeItem("userLoginStatus");
          localStorage.removeItem("push_status");
          localStorage.removeItem("active_profile_id");
          localStorage.removeItem("userType");
          this.setState({ tokenInvalid: true });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }
      })
      .catch(function (error) { });


    api
      .postMethod("home_second_section", inputData)
      .then((response) => {
        //console.log(response);
        if (response.data.success === true) {
          let maindata2 = response.data.data;

          this.setState({
            loading2: false,
            maindata2: maindata2,
          });
        } else {
          let errorHandle = 1;
          this.setState({ errorHandle });
        }

      })
      .catch(function (error) { });


  }

  renderVideoList = (maindata, index) => {
    return (
      <React.Fragment key={index}>
        <div className="main-slidersec">
          <Link
            to={{
              pathname: "/view-all",
              state: {
                url_type: maindata.url_type,
                url_type_id: maindata.url_type_id,
                page_type: "HOME",
                title: maindata.title,
              },
            }}
          >
            <h3 className="">
              {maindata.title}
              <i className="fas fa-angle-right ml-2" />
            </h3>
          </Link>

          <Slider>
            {maindata.data.map((movie) => (
              <Slider.Item movie={movie} key={movie.admin_video_id}>
                item1
              </Slider.Item>
            ))}
          </Slider>
        </div>
      </React.Fragment>
    );
  };


  render() {
    const { t } = this.props;

    const { loading, maindata, maindata2, loading2, banner, tokenInvalid } = this.state;

    if (tokenInvalid) {
      return <Redirect to="/login" />
    } else {
      return (
        <div className="main-sec-content">
          {loading ? <HomeLoader /> : <HomePageBanner banner={banner} />}
          <div className="main p-40 home-slider-top">
            {/* {renderMyList} */}

            {loading
              ? ""
              : maindata.map((mainDa, index) =>
                mainDa.data.length === 0
                  ? ""
                  : loading
                    ? t("loading")
                    : this.renderVideoList(mainDa, index)
              )}


            {loading2
              ? ""
              : maindata2.map((mainDa2, index2) =>
                mainDa2.data.length === 0
                  ? ""
                  : loading2
                    ? t("loading")
                    : this.renderVideoList(mainDa2, index2)
              )}

            <div className="height-100" />
          </div>
        </div>
      );
    }


  }
}

export default translate(Home);
