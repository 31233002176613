import React from "react";
import { Link } from "react-router-dom";
import Helper from "../../Helper/helper";
import ContentLoader from "../../Static/contentLoader";
import publicIp from "public-ip";

import api from "../../../Environment";

import io from "socket.io-client";

// import Plyr from 'plyr-react'
// import 'plyr-react/dist/plyr.css'

import ReactPlyr from '@m2g/react-plyr';
import '@m2g/react-plyr/build/react-plyr.css';

import VideoPlayer from "./VideoPlayer";

import { apiConstants } from "../../Constant/constants";

const socket = apiConstants.socketUrl ? io(apiConstants.socketUrl) : "";

let userId = localStorage.getItem("userId");

let accessToken = localStorage.getItem("accessToken");

let completeUpdated = false;

let totalDurationSeconds = 0;

class VideoComponent extends Helper {
  state = {
    loadingFirst: true,
    redirect: false,
    videoDetailsFirst: null,
    onPlayStarted: false,
    videoList: {},
    videoData: null,
    videoId: 0,
    socket: false,
    query: "",
    onSeekPlay: true,
    socketConnection: true,
    videoDuration: 0,
    socketConnected: false,
    autoplay: false,
    changeVideo: false,
    sources: {
      type: 'video',
      autoplay: true,
      sources: [
        {
          src: 'https://content.jwplatform.com/videos/GpRpJm1F-MTHxas9S.mp4',
          // provider: 'youtube',
          type: 'video/mp4',
        },
      ]
    },
    options: {
      controls: [
        'rewind',
        'play',
        'fast-forward',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'settings',
        'fullscreen',
        'thum'
      ],
      // i18n: {
      //   restart: 'Restart',
      //   rewind: 'Rewind {seektime}s',
      //   play: 'Play',
      //   pause: 'Pause',
      //   fastForward: 'Forward {seektime}s',
      //   seek: 'Seek',
      //   seekLabel: '{currentTime} of {duration}',
      //   played: 'Played',
      //   buffered: 'Buffered',
      //   currentTime: 'Current time',
      //   duration: 'Duration',
      //   volume: 'Volume',
      //   mute: 'Mute',
      //   unmute: 'Unmute',
      //   enableCaptions: 'Enable captions',
      //   disableCaptions: 'Disable captions',
      //   download: 'Download',
      //   enterFullscreen: 'Enter fullscreen',
      //   exitFullscreen: 'Exit fullscreen',
      //   frameTitle: 'Player for {title}',
      //   captions: 'Captions',
      //   settings: 'Settings',
      //   menuBack: 'Go back to previous menu',
      //   speed: 'Speed',
      //   normal: 'Normal',
      //   quality: 'Quality',
      //   loop: 'Loop',
      // },
    },
  };

  timeToSeconds = (timeString) => {
    const [hh = '0', mm = '0', ss = '0'] = (timeString || '0:0:0').split(':');
    const hour = parseInt(hh, 10) || 0;
    const minute = parseInt(mm, 10) || 0;
    const second = parseInt(ss, 10) || 0;
    return (hour * 3600) + (minute * 60) + (second);
  }

  secondsToTime = (videoSeconds) => {
    var date = new Date(null);
    date.setSeconds(videoSeconds);
    var durationInSeconds = date.toISOString().substr(11, 8);
    return durationInSeconds;
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ loadingFirst: false });
    } else {
      window.location = "/home";
    }
  }

  timer = async () => {
    if (this.state.onPlayStarted) {
      await this.socketConnectionfun(userId, accessToken);
    }
  };

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  onCompleteVideo = () => {
    this.addHistory(this.props.location.state.videoDetailsFirst.admin_video_id);
    this.setState({ onPlayStarted: false, socketConnection: false });
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
  };

  onVideoPlay = async () => {
    let intervalId = setInterval(this.timer, 3000);

    this.setState({ intervalId: intervalId });

    this.setState({ onPlayStarted: true, socketConnection: true });

    let inputData = {
      admin_video_id: this.props.location.state.videoDetailsFirst
        .admin_video_id,
    };
    await this.onlySingleVideoFirst(inputData);

    this.redirectStatus(this.state.videoDetailsFirst);

    const seekTime = this.state.videoDetailsFirst.seek_time_in_seconds
      ? this.state.videoDetailsFirst.seek_time_in_seconds
      : 0;

    console.log(seekTime);

    if (this.state.onSeekPlay) {
      this.player.seekTo(parseFloat(seekTime));
    }

    this.setState({ onSeekPlay: false });
  };

  addHistory = (admin_video_id) => {
    api
      .postMethod("addHistory", { admin_video_id: admin_video_id })
      .then((response) => {
        if (response.data.success === true) {
        } else {
        }
      })
      .catch(function (error) { });
  };

  getClientIp = async () => await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"]
  });

  updatePPV = () => {
    let user_name = localStorage.getItem("active_profile_name");
    this.getClientIp().then(ipaddress => {
      api
        .postMethod("videos_track", { admin_video_id: this.props.location.state.videoDetailsFirst.admin_video_id, name: user_name, ip_address: ipaddress })
        .then((response) => {
          if (response.data.success === true) {
          } else {
          }
        })
        .catch(function (error) { });
    })

  }

  updateWatchedDuration = (watchedDuration) => {
    api
      //.postMethod("save/watching/video", { admin_video_id: this.props.location.state.videoDetailsFirst.admin_video_id, duration: watchedDuration })
      .postMethod("continue_watching_videos/save", { admin_video_id: this.props.location.state.videoDetailsFirst.admin_video_id, duration: Math.round(watchedDuration) })
      .then((response) => {
        if (response.data.success === true) {
        } else {
        }
      })
      .catch(function (error) { });
    console.log((watchedDuration + " / " + totalDurationSeconds));
    if ((watchedDuration / totalDurationSeconds) > 0.75 && !completeUpdated) {
      api
        .postMethod("oncomplete/video", { admin_video_id: this.props.location.state.videoDetailsFirst.admin_video_id })
        .then((response) => {
          if (response.data.success === true) {
            completeUpdated = true;
          } else {
          }
        })
        .catch(function (error) { });
    }
  }

  playAnotherVideo = async (video_id) => {
    let inputData = {
      admin_video_id: video_id,
    };

    await this.onlySingleVideoFirst(inputData);
    this.setState({ changeVideo: true, redirect: true });
  }

  socketConnectionfun = (userId, accessToken) => {
    if (apiConstants.socketUrl) {
      let videoId = this.props.location.state.videoDetailsFirst.admin_video_id;

      socket.on("connect", function () {
        let query = `user_id=` + userId + `&video_id=` + videoId;
      });

      socket.on("connected", function () {
        console.log("Connected");
        this.setState({ socketConnected: true });
      });

      socket.on("disconnect", function () {
        console.log("disconnect");
        this.setState({ socketConnected: false });
      });

      console.log(this.state.videoDuration);

      let videoData = [
        {
          sub_profile_id: "",
          admin_video_id: videoId,
          id: userId,
          token: accessToken,
          duration: this.state.videoDuration,
        },
      ];

      socket.emit("save_continue_watching_video", videoData[0]);
    }
  };

  onPauseVideo = async () => {
    console.log("onPause");
    if (this.state.socketConnected) {
      socket.emit("disconnect");
    }
    clearInterval(this.state.intervalId);
  };

  onVideoTimeUpdate = (duration) => {
    let video_duration = duration.target.currentTime;

    let sec = parseInt(video_duration % 60);
    let min = parseInt((video_duration / 60) % 60);
    let hours = parseInt(video_duration / 3600);

    if (hours > 1) {
      this.setState({ videoDuration: hours + ":" + min + ":" + sec });
    } else {
      this.setState({ videoDuration: min + ":" + sec });
    }
  };

  ref = (player) => {
    this.player = player;
  };

  render() {

    // add the component in the render function
    //  return <Plyr
    //     isHls
    //     type='video'
    //     title='My title'
    //     url="https://stream.mux.com/Wxle5yzErvilJ02C13zuv8OSeROvfwsjS.m3u8"
    //   />;

    if (this.state.changeVideo) {
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst
      );
      if (returnToVideo != null) {
        return returnToVideo;
      }
    }

    if (this.state.onPlayStarted) {
      const pageType = "videoPage";
      const returnToVideo = this.renderRedirectPage(
        this.state.videoDetailsFirst,
        pageType
      );
      if (returnToVideo != null) {
        return returnToVideo;
      }
    }
    const { loadingFirst } = this.state;
    let mainVideo, videoTitle, videoType, subTitle, skipIntroStart = 0, skipIntroEnd = 0, skipCreditsStart = 0, skipCreditsEnd = 0, initialSeekToSeconds = 0, ppvValue = 0, prevVideoID = 0, nextVideoID = 0;
    if (loadingFirst) {
      return <ContentLoader />;
    } else {
      // Check the whether we need to play the trailer or main video

      if (this.props.location.state.videoFrom != undefined) {
        subTitle = this.props.location.state.videoDetailsFirst
          .video_subtitle_vtt;

        if (this.props.location.state.videoFrom == "trailer") {
          mainVideo = this.props.location.state.videoDetailsFirst.resolutions
            .original;
          subTitle = this.props.location.state.videoDetailsFirst
            .trailer_subtitle;
        } else {
          mainVideo = this.props.location.state.videoDetailsFirst.resolutions
            .original;
        }

        videoTitle = this.props.location.state.videoDetailsFirst.name;
        videoType = this.props.location.state.videoDetailsFirst.video_type;
      } else {
        mainVideo = this.props.location.state.videoDetailsFirst.main_video;

        subTitle = this.props.location.state.videoDetailsFirst
          .video_subtitle_vtt;

        videoTitle = this.props.location.state.videoDetailsFirst.title;

        videoType = this.props.location.state.videoDetailsFirst.video_type;
      }
      skipIntroStart = this.props.location.state.videoDetailsFirst.skip_intro_start;
      skipIntroEnd = this.props.location.state.videoDetailsFirst.skip_intro_end;
      skipCreditsStart = this.props.location.state.videoDetailsFirst.skip_credits_start;
      skipCreditsEnd = this.props.location.state.videoDetailsFirst.skip_credits_end;
      initialSeekToSeconds = (this.props.location.state.videoDetailsFirst.seek_time_in_seconds) ? this.props.location.state.videoDetailsFirst.seek_time_in_seconds : 0;
      prevVideoID = this.props.location.state.videoDetailsFirst.previous_episode;
      nextVideoID = this.props.location.state.videoDetailsFirst.next_episode;

      ppvValue = this.props.location.state.videoDetailsFirst.ppv_value;
      totalDurationSeconds = this.timeToSeconds(this.props.location.state.videoDetailsFirst.duration);
      console.log(this.props.location.state.videoDetailsFirst);
      var sourcesData = this.state.sources;
      sourcesData.sources[0].src = mainVideo;

      return (
        <div>
          <div className="single-video">

            {/* <SimpleVideoPlayer /> */}
            <VideoPlayer
              videoURL={mainVideo}
              skipIntroStart={skipIntroStart}
              skipIntroEnd={skipIntroEnd}
              skipCreditsStart={skipCreditsStart}
              skipCreditsEnd={skipCreditsEnd}
              initialSeekToSeconds={initialSeekToSeconds}
              ppvValue={ppvValue}
              updatePPV={this.updatePPV}
              updateWatchedDuration={this.updateWatchedDuration}
              prevVideoID={prevVideoID}
              nextVideoID={nextVideoID}
              playAnotherVideo={this.playAnotherVideo}
              videoDetails={this.props.location.state.videoDetailsFirst} />
            {/* <ReactPlyr
              isHls
              autoplay={true}
              seekTime={10}
              type='video'
              controls={['play-large', 'restart', 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'fullscreen']}
              // title='My title'
              url={mainVideo}
            /> */}

            {/* <Plyr
              isHls
              type='video'
              title='My title'
              // url="https://stream.mux.com/Wxle5yzErvilJ02C13zuv8OSeROvfwsjS.m3u8"
            /> */}
            {/*             
            <Plyr
              ref='videoplayer'
              options={this.state.options}
              // autoPlay={true}
              source={sourcesData}
            /> */}

            {/* <ReactPlayer
              ref={this.ref}
              // url={[
              //   {
              //     src:
              //       "http://adminview.streamhash.com:8080/426x240SV-201…8-59-443b8c7d4d68e41bb9a618a0de9a5f4003710241.mp4",
              //     type: "video/webm"
              //   },

              //   {
              //     src:
              //       "http://adminview.streamhash.com:8080/640x360SV-2019-09-23-05-18-59-443b8c7d4d68e41bb9a618a0de9a5f4003710241.mp4",
              //     type: "video/ogg"
              //   }
              // ]}
              url={mainVideo}
              controls={true}
              width="100%"
              height="100vh"
              playing={true}
              onStart={this.onLoad}
              onPause={this.onPauseVideo}
              onPlay={
                this.props.location.state.videoFrom == "trailer"
                  ? ""
                  : this.onVideoPlay
              }
              onEnded={this.onCompleteVideo}
              onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
              light={this.props.location.state.videoDetailsFirst.default_image}
              config={{
                file: {
                  tracks: [
                    {
                      kind: "subtitles",
                      src: subTitle,
                      srcLang: "en",
                      default: true,
                    },
                  ],
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            /> */}
            <div className="back-arrowsec"
            // onClick={() => this.props.history.goBack()} 
            >
              <Link to="/home">
                <img
                  src={window.location.origin + "/assets/img/left-arrow.png"}
                  alt="arrow"
                />
                {videoType == 2 ? (
                  ""
                ) : (
                  <span className="txt-overflow capitalize ml-3">
                    {videoTitle}
                  </span>
                )}
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default VideoComponent;
